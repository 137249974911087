<template>
  <v-container grid-list-md>
    <v-layout
      v-if="prospectiveStudent"
      row
      wrap
    >
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex xs10>
            <h2>{{ heading }}</h2>
            <span class="subheading">
              {{ subheading }}
            </span>
          </v-flex>
          <v-flex
            xs2
            text-xs-right
          >
            <v-menu
              offset-y
              bottom
              right
            >
              <v-btn
                slot="activator"
                color="primary"
                dark
              >
                Apply New Fee
              </v-btn>
              <v-list v-if="applicableFees">
                <v-list-tile
                  v-for="(fee, index) in applicableFees"
                  :key="fee.title + index"
                  @click="add(fee)"
                >
                  <v-list-tile-title>
                    {{ fee.title }}
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-divider class="my-3" />
      </v-flex>
      <v-flex xs12>
        <v-data-iterator
          v-if="feeList"
          :items="feeList"
          :rows-per-page-items="rowsPerPageItems"
          :pagination.sync="pagination"
          content-tag="v-layout"
          row
          wrap
          expand
          hide-actions
        >
          <v-flex
            slot="item"
            slot-scope="props"
            xs12
            sm6
            md3
          >
            <v-card>
              <v-card-title>
                <h4>{{ props.item.title }}</h4>
              </v-card-title>
              <v-divider />
              <v-list dense>
                <v-list-tile>
                  <v-list-tile-content>Pay Amount:</v-list-tile-content>
                  <v-list-tile-content class="align-end">
                    {{ `$ ${ props.item.amount }` }}
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>Discount:</v-list-tile-content>
                  <v-list-tile-content class="align-end">
                    {{ `${ props.item.discountRate }%` }}
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>Method:</v-list-tile-content>
                  <v-list-tile-content class="align-end">
                    {{ props.item.deductMethod }}
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>Activate:</v-list-tile-content>
                  <v-list-tile-content class="align-end">
                    <v-switch
                      value
                      :input-value="props.item.activated === 'y'"
                    />
                  </v-list-tile-content>
                </v-list-tile>

                <v-list-tile>
                  <v-list-tile-content class="align-start">
                    <v-btn
                      small
                    >
                      History
                    </v-btn>
                  </v-list-tile-content>
                  <v-list-tile-content class="align-end">
                    <v-btn
                      small
                    >
                      Deposit
                    </v-btn>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-card>
          </v-flex>
        </v-data-iterator>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ProspectiveStudentFees',
  data() {
    return {
      prospectiveStudent: null,
      transactions: null,
      fees: [],
      prospectiveStudentFees: [],
      rowsPerPageItems: [4, 8, 12],
      pagination: {
        rowsPerPage: 99,
      },
    }
  },
  computed: {
    heading() {
      return `${this.prospectiveStudent.firstName} ${this.prospectiveStudent.lastName}'s Applicable Fees`
    },
    subheading() {
      return `Student ID: ${this.prospectiveStudent.prospectiveStudentId},
              Gender: ${this.prospectiveStudent.gender || 'None'},
              Date of Birth: ${this.prospectiveStudent.dateOfBirth || 'None'}`
    },
    applicableFees() {
      return this.fees.filter(fee =>
        !this.prospectiveStudentFees.some(studentFee => studentFee.feeId === fee.id))
    },
    feeList() {
      return this.prospectiveStudentFees.map(studentFee => ({ ...studentFee, ...this.getFee(studentFee.feeId) }))
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('prospectiveStudents/one', this.$route.params.id)
        .then((data) => { this.prospectiveStudent = data })

      this.$store.dispatch('fees/all')
        .then((data) => { this.fees = data })

      this.$store.dispatch('prospectiveStudentFees/all', { prospectiveStudentId: this.$route.params.id })
        .then((data) => { this.prospectiveStudentFees = data })
    },
    getFee(id) {
      return this.fees.find(fee => fee.id === id)
    },
    add(fee) {
      const studentFee = {
        prospectiveStudentId: this.$route.params.id,
        feeId: fee.id,
      }
      this.$store.dispatch('prospectiveStudentFees/create', studentFee)
        .then(data => this.prospectiveStudentFees.push(data))
    },
  },
}
</script>

<style>
</style>
